import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HistoryList from './HistoryList';
import HistoryContentPane from './HistoryContentPane';
import { SelectedHistoryItemProvider } from './SelectedHistoryItemContext';
import { selectHistoryData, selectHistoryStatus, selectHistoryError, fetchHistory } from '../redux/historySlice';
import { selectUserData } from '../redux/userSlice';
import * as ApiStatus from '../redux/api-status';

const History = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const historyData = useSelector(selectHistoryData);
  const historyStatus = useSelector(selectHistoryStatus);
  const historyError = useSelector(selectHistoryError);

  useEffect(() => {
    if (historyStatus === ApiStatus.IDLE && userData && userData.email) {
      dispatch(fetchHistory(userData.email));
    }
  }, [historyStatus, dispatch, userData]);

  if (historyError) {
    return (
      <>
        <div>Error loading history:</div>
        <div>{historyError}</div>
      </>
    );
  }

  if (historyData.length > 0) {
    return (
      <>
        <SelectedHistoryItemProvider>
          <div className="columns no-wrap margin-all-10px">
            <HistoryList history={historyData} />
            <HistoryContentPane history={historyData} />
          </div>
        </SelectedHistoryItemProvider>
      </>
    );
  } else if (historyStatus === ApiStatus.LOADING) {
    return <div>Loading history...</div>;
  } else if (historyData.length === 0) {
    return (
      <>
        <div>You have no conversation history yet.</div>
        <div>The current conversation is saved any time you sign out or clear the history in the chat window.</div>
      </>
    );
  }
}

export default History;
