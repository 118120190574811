import React from 'react';
import { useSelector } from 'react-redux';
import List from '../design-system/list/List';
import { useSelectedHistoryItem } from './SelectedHistoryItemContext';
import { selectUserData } from '../redux/userSlice';
import './history.css';
import '../css/field.css';

const extractConversation = (history, conversationKey) => {
  for (let i = 0; i < history.length; i++) {
    if (history[i][0].email + "-" + history[i][0].conversationNumber == conversationKey) {
      return history[i];
    }
  }
}

const HistoryContentPane = ({ history, overrideUsername }) => {
  const [selectedHistoryItem, setSelectedHistoryItem] = useSelectedHistoryItem();
  const userData = useSelector(selectUserData);

  if (selectedHistoryItem) {
    const content = extractConversation(history, selectedHistoryItem);
    if (content) {
      // Special case to override user handle with conversation email when viewed from the flagged messages page
      const username = overrideUsername ? content[0].email : (userData.handle || "User");
      return (
        <List className="big-col">
          <div className="full-width">
            <div className="fake-field">
              {content.map(question => (
                <div key={`key-${question.timestamp}`}>
                  {/* <p key={index}  className="padding-bottom-10px"></p> */}
                  <div className="padding-bottom-10px" style={{ textAlign: "right" }}><strong>{username}:</strong> {question.inputText}</div>
                  <div className="padding-bottom-10px" style={{ textAlign: "left" }}><strong>Joe Chip:</strong> {question.responseText}</div>
                </div>
              ))}
            </div>
          </div>
        </List>
      );
    } else {
      // This is to cover the case where we select an item for user A in the flagged messages page
      // and then load data for user B. The page re-renders and fails because it's still using a key
      // from user A but it has user B's data. The solution is to render nothing until the loading
      // is complete and the page will fix itself.
      return null;
    }
  } else {
    return (<div className="history-content-pane">Select a conversation from the history</div>);
  }
}

export default HistoryContentPane;
