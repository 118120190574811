import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as ApiStatus from './api-status';
import { getHistory } from '../firestore/firestore-collection-questions';

const initialState = {
  data: [],
  status: ApiStatus.IDLE,
  error: null
}

export const fetchHistory = createAsyncThunk('history/fetchHistory', async (userEmail) => await getHistory(userEmail));

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHistory.pending, (state, action) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(fetchHistory.fulfilled, (state, action) => {
        state.status = ApiStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchHistory.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.error = action.error.message;
      })
  }
});

// FIXME No actions as history is read-only
// export const { postAdded, postUpdated, reactionAdded } = postsSlice.actions

export default historySlice.reducer;

export const selectHistoryData = state => state.history.data;
export const selectHistoryStatus = state => state.history.status;
export const selectHistoryError = state => state.history.error;

// export const FooComponent = () => {
//   const dispatch = useDispatch()
//   const history = useSelector(selectAllHistory)
//   const historyStatus = useSelector(state => state.history.status)
//   useEffect(() => {
//     if (historyStatus === 'idle') {
//       dispatch(fetchHistory())
//     }
//   }, [historyStatus, dispatch])
//   // omit rendering logic
//   // ...
// }
