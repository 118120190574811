import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../design-system/dropdown/Dropdown';
import { useFlaggedMessagesFilters } from './FlaggedMessagesFiltersContext';
import { fetchEmails, selectEmailsData } from '../redux/emailsSlice';

const EmailFilter = () => {
  const dispatch = useDispatch();
  const emailsData = useSelector(selectEmailsData);
  const [filters, setFilters] = useFlaggedMessagesFilters({});

  useEffect(() => {
    dispatch(fetchEmails());
  }, []);

  if (!emailsData) {
    return <div>Loading emails</div>;
  }

  return (
    <Dropdown 
      label="Email"
      value={filters["user"]}
      onChange={e => setFilters({ ...filters, email: e.target.value })}
    >
      <option className="filter-option" value="">All users</option>
      {emailsData.map(email => (<option className="filter-option" key={email || "empty"}>{email}</option>))}
    </Dropdown>
  );
}

export default EmailFilter;
