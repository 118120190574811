import * as React from 'react'

const FlaggedMessagesFiltersContext = React.createContext()

function useFlaggedMessagesFilters() {
  const context = React.useContext(FlaggedMessagesFiltersContext)
  if (!context) {
    throw new Error(`useFlaggedMessagesFilters must be used within a FlaggedMessagesFiltersProvider`)
  }
  return context
}

function FlaggedMessagesFiltersProvider(props) {
  const defaultValues = { email: "", timestampStart: "", timestampEnd: "", flagged: "true" };
  const [filters, setFilters] = React.useState(defaultValues)
  const value = React.useMemo(() => [filters, setFilters], [filters])
  return <FlaggedMessagesFiltersContext.Provider value={value} {...props} />
}

export { FlaggedMessagesFiltersProvider, useFlaggedMessagesFilters }
