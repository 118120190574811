import React, { useState } from 'react';
import Button from '../design-system/button/Button';
import FlagAnswerDialog from './FlagAnswerDialog';

const FlagCurrentAnswerButton = () => {
  const [showDialog, setShowDialog] = useState(false);

  const onClick = async (e) => {
    e.preventDefault();
    setShowDialog(true);
  }

  return (
    <>
      {showDialog ? <FlagAnswerDialog setShowDialog={setShowDialog} /> : null}
      <Button onClick={onClick}>Flag current answer</Button>
    </>
  );
}

export default FlagCurrentAnswerButton;
