import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as ApiStatus from './api-status';
import serverApi from '../firestore/server-api';

const initialState = {
  data: [],
  status: ApiStatus.IDLE,
  error: null
}

export const fetchDebugData = createAsyncThunk('debug/fetchDebugData', async () => await serverApi.getDebugData());

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDebugData.pending, (state, action) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(fetchDebugData.fulfilled, (state, action) => {
        state.status = ApiStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchDebugData.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.error = action.error.message;
      })
  }
});

export default debugSlice.reducer;

export const selectDebugData = state => state.debug.data;
export const selectDebugStatus = state => state.debug.status;
export const selectDebugError = state => state.debug.error;
