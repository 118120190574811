import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Text from '../design-system/text/Text';
import { SERVER_URL } from '../firestore/get-server-url';
import commitData from '../../client-commit.json';
import { selectDebugData, selectDebugStatus, selectDebugError, fetchDebugData } from '../redux/debugSlice';
import * as ApiStatus from '../redux/api-status';

const DebugData = () => {
  const dispatch = useDispatch();
  const debugData = useSelector(selectDebugData);
  const debugStatus = useSelector(selectDebugStatus);
  const debugError = useSelector(selectDebugError);

  useEffect(() => {
    if (debugStatus === ApiStatus.IDLE) {
      dispatch(fetchDebugData());
    }
  }, [debugStatus, dispatch]);

  const renderClientCommit = () => (
    <>
      <Text>Client commit:</Text>
      <Text>{commitData.fullCommitHash}</Text>
      <Text>Local changes at time of upload:</Text>
      {commitData.localChanges.length > 0
        ? commitData.localChanges.map(line => <Text>{line}</Text>)
        : "(none)"
      }
    </>
  )

  const renderServerCommit = () => {
    if (debugStatus === ApiStatus.SUCCEEDED) {
      return (
        <>
          <Text>Server commit:</Text>
          <Text>{debugData.fullCommitHash}</Text>
          <Text>Local changes at time of upload:</Text>
          {debugData.localChanges.length > 0
            ? debugData.localChanges.map(line => <Text>{line}</Text>)
            : "(none)"
          }
        </>
      );
    }
    return <>Unknown server commit</>
  }

  return (
    <>
      <Text bold="true">Debug info</Text>
      <Text>Environment: {process.env.NODE_ENV}</Text>
      <Text>Server URL: {SERVER_URL}</Text>
      {renderClientCommit()}
      {renderServerCommit()}
    </>
  );
}

export default DebugData;
