// Importing the openDB function from 'idb', a promise-based wrapper around IndexedDB API.
import { openDB } from 'idb';

// Define an async function that sets up your database.
const setupDatabase = async () => {
  // Call the openDB function, passing in the name of your database and the version number.
  // openDB will either open an existing database or create a new one if it doesn't exist.
  // The function returns a promise that resolves to a DB instance, which you can await.
  const db = await openDB('chatDB', 1, {
    // The upgrade callback is executed if the database is being created for the first time
    // or if the provided version number is higher than the existing database's version.
    // Inside this callback, you define your database's structure.
    upgrade(db) {
      // Here, you're creating an object store named 'conversations'.
      // The keyPath option specifies that the 'userId' property of the stored objects will be used as their key.
      db.createObjectStore('conversations', { keyPath: 'userId' });
    },
  });

  // Return the DB instance to the caller, which can be used to interact with the database.
  return db;
};

// Export the setupDatabase function as the default export of this module, 
// so it can be imported in other parts of your application.
export default setupDatabase;
