import React from 'react';
import { FlaggedMessagesFiltersProvider } from './FlaggedMessagesFiltersContext';
import FlaggedMessagesFilters from './FlaggedMessagesFilters';
import HistoryList from '../history/HistoryList';
import HistoryContentPane from '../history/HistoryContentPane';
import FlaggedDetails from './FlaggedDetails';
import { SelectedHistoryItemProvider } from '../history/SelectedHistoryItemContext';
import { selectFlaggedMessagesData, selectFlaggedMessagesStatus, selectFlaggedMessagesError } from '../redux/flaggedMessagesSlice';
import * as ApiStatus from '../redux/api-status';
import { useSelector } from 'react-redux';

const FlaggedMessages = () => {
  const flaggedMessagesData = useSelector(selectFlaggedMessagesData);
  const flaggedMessagesStatus = useSelector(selectFlaggedMessagesStatus);
  const flaggedMessagesError = useSelector(selectFlaggedMessagesError);

  const renderOuter = innerComponent => {
    return (
      <>
        <FlaggedMessagesFiltersProvider>
          <FlaggedMessagesFilters />
          {innerComponent}
        </FlaggedMessagesFiltersProvider>
      </>
    )
  }

console.log(`flaggedMessagesData = ${JSON.stringify(flaggedMessagesData)}`);

  const renderInner = () => {
    if (flaggedMessagesData.length > 0) {
      return (
        <>
          <SelectedHistoryItemProvider>
            <div className="columns no-wrap margin-all-10px">
              <HistoryList history={flaggedMessagesData} />
              <HistoryContentPane history={flaggedMessagesData} overrideUsername={true} />
              <FlaggedDetails history={flaggedMessagesData} />
            </div>
          </SelectedHistoryItemProvider>
        </>
      );
    } else if (flaggedMessagesData.length === 0) {
      return <div>No results</div>;
    } else if (flaggedMessagesStatus === ApiStatus.LOADING) {
      return <div>Loading flagged messages...</div>;
    } else {
      return <div>Load some data using the filters.</div>;
    }
  }

  return renderOuter(renderInner());
}

export default FlaggedMessages;
