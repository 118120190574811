import * as React from 'react'

const SelectedHistoryItemContext = React.createContext()

function useSelectedHistoryItem() {
  const context = React.useContext(SelectedHistoryItemContext)
  if (!context) {
    throw new Error(`useSelectedHistoryItem must be used within a SelectedHistoryItemProvider`)
  }
  return context
}

function SelectedHistoryItemProvider(props) {
  const [conversationKey, setConversationKey] = React.useState(null)
  const value = React.useMemo(() => [conversationKey, setConversationKey], [conversationKey])
  return <SelectedHistoryItemContext.Provider value={value} {...props} />
}

export { SelectedHistoryItemProvider, useSelectedHistoryItem }
