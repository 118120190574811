import { configureStore } from '@reduxjs/toolkit';
import { createGlobalStateReducer } from '@folkforms/redux-global-state-hooks';
import debugReducer from './debugSlice';
import editUsersReducer from './editUsersSlice';
import emailsReducer from './emailsSlice';
import flaggedMessagesReducer from './flaggedMessagesSlice';
import historyReducer from './historySlice';
import userReducer from './userSlice';

export default configureStore({
  reducer: {
    chatGptModel: createGlobalStateReducer('chatGptModel', 'gpt-4-0613'),
    debug: debugReducer,
    editUsers: editUsersReducer,
    emails: emailsReducer,
    flaggedMessages: flaggedMessagesReducer,
    history: historyReducer,
    personality: createGlobalStateReducer('personality', 'JoePokerCoach'),
    prompt: createGlobalStateReducer('prompt', 'Single Prompt'),
    user: userReducer,
  }
});
