import React, { useState } from 'react';
import Button from '../button/Button';
import Dropdown from '../dropdown/Dropdown';
import List from '../list/List';
import Textfield from '../textfield/Textfield';
import Text from '../text/Text';
import Textarea from '../textarea/Textarea';
import ExampleModal from './ExampleModal';
import '../design-system.css';
import './DesignSystemPreview.css';
import Message from '../message/Message';

const DesignSystemPreview = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  return (
    <div className="design-system-preview">
      <div className="design-system-preview-layout">

        <div className="even full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Buttons:</strong></div>
          <div className="design-system-preview-section">
            <Button onClick={() => alert("ok")}>Click me</Button>
          </div>
        </div>

        <div className="odd full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Textfields:</strong></div>
          <div className="design-system-preview-section">
            <Textfield defaultValue="abc" onChange={e => console.log(e.target.value)} />
            <Textfield label="Label" defaultValue="abc" onChange={e => console.log(e.target.value)} />
            <Textfield label="Label" labelposition="top" defaultValue="abc" onChange={e => console.log(e.target.value)} />
          </div>
        </div>

        <div className="even full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Lists:</strong></div>
          <div className="design-system-preview-section">
            <List>
              <div>List item 1</div>
              <div>List item 2</div>
              <div>List item 3</div>
            </List>
          </div>
        </div>

        <div className="odd full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Dropdowns:</strong></div>
          <div className="design-system-preview-section">
            <Dropdown>
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </Dropdown>
            <Dropdown label="Label">
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </Dropdown>
            <Dropdown label="Label" labelposition="top">
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </Dropdown>
          </div>
        </div>

        <div className="even full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Text:</strong></div>
          <div className="design-system-preview-section">
            <Text>Some text</Text>
            <Text bold="true">Some bold text</Text>
          </div>
        </div>

        <div className="odd full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Textareas:</strong></div>
          <div className="design-system-preview-section">
            <Textarea defaultValue="Textarea" rows={10} />
            <Textarea defaultValue="Textarea" rows={10} label="Label" labelposition="top" />
          </div>
        </div>

        <div className="even full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Modals:</strong></div>
          <div className="design-system-preview-section">
            {showModal ? <ExampleModal setShowModal={toggleModal} /> : null}
            <Button onClick={toggleModal}>Show modal</Button>
          </div>
        </div>

        <div className="odd full-width padding-all-10px">
          <div className="design-system-preview-label"><strong>Messages:</strong></div>
          <div className="design-system-preview-section">
            <Message>Default/info message</Message>
            <Message type="success">Success message</Message>
            <Message type="warning">Warning message</Message>
            <Message type="error">Error message</Message>
          </div>
        </div>

      </div>
    </div>
  );
}

export default DesignSystemPreview;
