import React from 'react';
import Textfield from '../design-system/textfield/Textfield';
import { useFlaggedMessagesFilters } from './FlaggedMessagesFiltersContext';

const TimestampFilter = ({ label, targetProperty }) => {
  const [filters, setFilters] = useFlaggedMessagesFilters({});

  return (
    <Textfield
      label={label}
      value={filters[targetProperty]}
      onChange={e => setFilters({ ...filters, [targetProperty]: e.target.value })}
    />
  );
}

export default TimestampFilter;
