import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../design-system/dropdown/Dropdown';
import { fetchEmails, selectEmailsData } from '../redux/emailsSlice';
import { fetchUserForEditing } from '../redux/editUsersSlice';
import Button from '../design-system/button/Button';

const SelectEmail = () => {
  const dispatch = useDispatch();
  const emailsData = useSelector(selectEmailsData);
  useEffect(() => {
    dispatch(fetchEmails());
  }, []);

  const [email, setEmail] = useState("");

  const onClick = e => {
    e.preventDefault();
    if (email) {
      dispatch(fetchUserForEditing(email));
    }
  }

  if (!emailsData) {
    return <div>Loading emails</div>;
  }

  return (
    <div className="columns">
      <Dropdown
        label="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      >
        <option className="filter-option" value="" />
        {emailsData.map(email => (<option className="filter-option" key={email || "empty"}>{email}</option>))}
      </Dropdown>
      <Button onClick={onClick}>
        <span className="filter-label">Load user</span>
      </Button>
    </div>
  );
}

export default SelectEmail;
