import React from 'react';
import { useSelectedHistoryItem } from '../history/SelectedHistoryItemContext';

const FlaggedDetails = ({ history }) => {
  const [selectedHistoryItem, setSelectedHistoryItem] = useSelectedHistoryItem();

  const extractConversation = (history, conversationKey) => {
    for (let i = 0; i < history.length; i++) {
      if (history[i][0].email + "-" + history[i][0].conversationNumber == conversationKey) {
        return history[i];
      }
    }
  }
  const convo = extractConversation(history, selectedHistoryItem);

  if (convo && convo.length > 0 && convo[0].flagged) {
    return (
      <div className="small-col-25em fake-field">
        <div><strong>Flagged because:</strong></div>
        <div>"{convo[0].flaggedDescription || "Unknown"}"</div>
      </div>
    );
  }
  return null;
}

export default FlaggedDetails;
