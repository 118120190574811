import React from 'react';
import ChatGptModelSelection from './ChatGptModelSelection';
import PersonalitySelection from './PersonalitySelection';
import PromptSelection from './PromptSelection';
import DebugData from './DebugData';

const AdminSettings = () => {
  return (
    <div className="cols padding-all-20px">
      <div className="rows">
        <ChatGptModelSelection />
        <PersonalitySelection />
        <PromptSelection />
      </div>
      <div className="rows">
        <DebugData />
        <strong>These are all just dummy controls for the minute...</strong>
      </div>
    </div>
  );
}

export default AdminSettings;
