import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Text from '../design-system/text/Text';
import { selectUserData, selectUserStatus } from '../redux/userSlice';
import * as ApiStatus from '../redux/api-status';
import { getUserSnapshot } from '../firestore/firestore-snapshots'; 

const MessageCounter = () => {
  const userData = useSelector(selectUserData);
  const userStatus = useSelector(selectUserStatus);
  const [count, setCount] = useState(userData?.messagesRemaining);

  const onNextCallback = docSnapshot => {
    setCount(docSnapshot.data().messagesRemaining);
  }
  const unsubscribe = getUserSnapshot(userData.uid, onNextCallback);

  if (userStatus !== ApiStatus.SUCCEEDED) {
    return null;
  }

  return <Text>You have {count} message{count === 1 ? "" : "s"} remaining this month</Text>;
}

export default MessageCounter;
