// app.js

// Importing necessary modules from react, firebase and the application
import React, { useState, useEffect } from 'react';
import './App.css';
import { auth, db } from '../Firebase/firebase';
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { SERVER_URL } from "./firestore/get-server-url";
import ChatWindow from './ChatWindow'; 
import Model_Selection from './Model_Selection';
import PersonalitySelection from './PersonalitySelection';
import PromptSelection from './PromptSelection';
import NavBar from './NavBar';
import Button from './design-system/button/Button';
import Textfield from './design-system/textfield/Textfield';
import List from './design-system/list/List';
import Text from './design-system/text/Text';
import './design-system/design-system.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FlagAnswerButton from './flag-button/FlagAnswerButton';
import store from './redux/store.js';
import { Provider } from 'react-redux';

// other pages
// FIXME
import Pricing from './ExtraPages/pricing/Pricing';
import PaymentSuccess from './ExtraPages/pricing/PaymentSuccess';
import PaymentFailure from './ExtraPages/pricing/PaymentFailure';
import How from './ExtraPages/How';
import Settings from './ExtraPages/Settings';
import History from './history/History';
import EditUsers from './edit-users/EditUsers';
import FlaggedMessages from './flagged-messages/FlaggedMessages';

import { useDispatch, useSelector } from 'react-redux';
import { userDataFetched, fetchUser, selectUserData } from './redux/userSlice';
import { refreshMessages } from './firestore/firestore-collection-users'
import MessageCounter from './message-counter/MessageCounter';
import DesignSystemPreview from './design-system/preview/DesignSystemPreview';
import WelcomeSection from './welcome-section/WelcomeSection';
import AdminSettings from './admin-settings/AdminSettings';

// LoginForm Component sModel_Selection
function LoginForm() {
  const dispatch = useDispatch();

  // State Variables using React's useState Hook
  const [loading, setLoading] = useState(false); // For Loading state
  const [error, setError] = useState(null); // To store any error message
  const [email, setEmail] = useState(''); // To store user's email
  const [password, setPassword] = useState(''); // To store user's password
  const [user, setUser] = useState(null); // To store authenticated user data
  const [selectedModel, setSelectedModel] = useState("gpt-4-0613");
  const [selectedPersonality, setSelectedPersonality] = useState("JoePokerCoach");
  const [selectedPrompt, setSelectedPrompt] = useState("Single Prompt");

  // Define state for email subscription and function to handle subscription
  const [emailSubscription, setEmailSubscription] = useState('');
  const handleEmailSubscription = async () => {
    if (emailSubscription.trim() === '') {
      alert('Please enter a valid email address.');
      return;
    }
  
    // Reference to Firestore collection
    const collectionRef = collection(db, 'subscriptions');
  
    try {
      // Adding the email to the collection
      await addDoc(collectionRef, {
        email: emailSubscription,
        subscribedOn: new Date() // Timestamp of the subscription
      });
  
      alert('Thank you for subscribing! We will keep you updated.');
      setEmailSubscription(''); // Clear the input field
    } catch (error) {
      console.error('Error saving subscription:', error);
      alert('Something went wrong. Please try again later.');
    }
  };
  
  const userData = useSelector(selectUserData);
  useEffect(() => {
    if (userData) {
      refreshMessages(userData.uid, userData.lastMessageRefreshDate);
    }
  }, [userData]);
  
  // Sign in function
  const signIn = async () => {
    // Set loading state to true during sign in process
    setLoading(true);
    // Reset error state before new sign in attempt
    setError(null);

    try {
      // Firebase sign in function
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      // If sign in fails, set error message and stop loading
      setError('Failed to sign in');
      setLoading(false);
    }
  };

  // Register function
  const register = async () => {
  // Set loading state to true during registration process
  setLoading(true);
  // Reset error state before new registration attempt
  setError(null);

  try {
    // Firebase create user function
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    // Create a user document in Firestore
    await setDoc(doc(db, "users", userCredential.user.uid), {
      handle: email.split('@')[0], // Create a handle from the email address
      email: email,
      role: "free",
      status: "user",
      timesInteractedWithBot: 0,
      lastMessageRefreshDate: Date.now(),
      messagesRemaining: 20, // FIXME
    });
  } catch (error) {
    // If registration fails, set error message and stop loading
    setError('Failed to create account');
    setLoading(false);
  }
};

  // Sign out function
  const signOutUser = async () => {
    // Set loading state to true during sign out process
    setLoading(true);
    // Reset error state before new sign out attempt
    setError(null);
    try {
      await signOut(auth);
    } catch (error) {
      // If sign out fails, set error message and stop loading
      setError('Failed to sign out');
      setLoading(false);
    }
  };

  // useEffect hook to setup firebase auth state changed listener
  useEffect(() => {
    // Listener for auth state changes (signed in, signed out, etc.)
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // Signed in
      if (user) {
        dispatch(fetchUser(user.uid));
        const userDataFromFirebaseForRedux = {
          email: user.email,
          uid: user.uid,
          idToken: user.stsTokenManager.accessToken,
          refreshToken: user.stsTokenManager.refreshToken,
        };
        dispatch(userDataFetched(userDataFromFirebaseForRedux));
      }
      // Set user information on auth state changed
      setUser(user);
      // Set loading state to false after getting user info
      setLoading(false);
    });

    // Cleanup function to unsubscribe from the listener when component unmounts
    return () => unsubscribe();
  }, [dispatch]);

  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  const showAdminLinks = userData?.role === "admin";

  // Render user info if user is signed in
  if (user) {
    return (
      <Router>
        <div className="app-container">
          <NavBar />
          <Routes>
            {showAdminLinks ? (
              <>
                <Route path="/admin-settings" element={<AdminSettings />} />
                <Route path="/design-system" element={<DesignSystemPreview />} />
                <Route path="/edit-users" element={<EditUsers />} />
                <Route path="/flagged-messages" element={<FlaggedMessages />} />
              </>
            ) : null}
            <Route path="/chat" element={
              <div className="app-main">
                <ChatWindow className="chat-window" 
                  selectedModel={selectedModel} 
                  selectedPersonality={selectedPersonality}
                  selectedPrompt={selectedPrompt}
                />
                <List className="margin-all-10px">
                  <Text bold="true">Welcome, {userData.handle}, you beautiful man</Text>
                  <Button onClick={signOutUser}>Sign out</Button>
                  <div>
                    <Model_Selection selectedModel={selectedModel} setSelectedModel={setSelectedModel} />
                  </div>
                  <div>
                    <PersonalitySelection selectedPersonality={selectedPersonality} setSelectedPersonality={setSelectedPersonality} />
                  </div>
                  <div>
                  <PromptSelection selectedPrompt={selectedPrompt} setSelectedPrompt={setSelectedPrompt} />
                  </div>

                  <Text>Environment: {process.env.NODE_ENV}</Text>
                  <Text>Server URL: {SERVER_URL}</Text>
                  <FlagAnswerButton />
                  {/*
                  This button tests sending an idToken to the server which will verify it server-side.
                  <TestIdsButton />
                  */}
                  <MessageCounter />
                </List>
              </div>
            } />
            <Route path="/history" element={<History />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-failure" element={<PaymentFailure />} />
            <Route path="/How" element={<How />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/" element={<Navigate to="/chat" />} />
          </Routes>
          {error && <div className='error'>{error}</div>}
        </div>
      </Router>
    );
  }

  // Render sign in/register form (Register button is temporarily commented out until we are ready for users)
  return (
    <div className='login-container'>
      <WelcomeSection />
      {/* Step 1: Add email subscription box here */}
      <div className="email-subscription" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Textfield
          type="email"
          value={emailSubscription}
          onChange={(e) => setEmailSubscription(e.target.value)}
          placeholder="Enter your email"
          labelposition="top"
          style={{ width: '200px' }}
        />
        <Button onClick={handleEmailSubscription}>Subscribe</Button>
      </div>

        {/* Login form */}
        <div className='login rows'>
            <div className="full-width">
                <Textfield type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" label="Email" labelposition="top"/>
            </div>
            <div className="full-width">
                <Textfield type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" label="Password" labelposition="top" />
            </div>
            <div className='full-width centered'>
                <Button onClick={signIn} className='button'>Sign in</Button>
            </div>
            {error && <div className="full-width"><div className="centered error">{error}</div></div>}
        </div>
    </div>
);


}

const LoginFormWithProvider = () => (
  <Provider store={store}>
    <LoginForm />
  </Provider>
);

// Export LoginForm component as default export
export default LoginFormWithProvider;
