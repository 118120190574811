import React from 'react';
import './Button.css';

const Button = (props) => {
  const className = "jcbutton" + (props.className ? ` ${props.className}` : "");

  return (
    <button {...props} className={className} />
  );
}

export default Button;
