import React from 'react';
import List from '../design-system/list/List';
import { useSelectedHistoryItem } from './SelectedHistoryItemContext';
import './history.css';
import '../css/field.css';

const createMap = history => {
  const map = {};
  for (let i = 0; i < history.length; i++) {
    const conversation = history[i];
    map[conversation[0].email + "-" + conversation[0].conversationNumber] = conversation[0].inputText;
  }
  return map;
}

const HistoryList = ({ history }) => {
  const map = createMap(history);
  const orderedKeys = Object.keys(map).sort();
  const [selectedHistoryItem, setSelectedHistoryItem] = useSelectedHistoryItem();
  return (
    <List className="small-col-25em">
      {orderedKeys.map(key => (
        <li className="full-width" key={key} onClick={() => setSelectedHistoryItem(key)}>
          <div className={`history-list-item ${key == selectedHistoryItem ? "selected" : ""}`}>
            {map[key]}
          </div>
        </li>
      ))}
    </List>
  );
}

export default HistoryList;
