import React from 'react';
import { useDispatch } from 'react-redux';
import { useFlaggedMessagesFilters } from './FlaggedMessagesFiltersContext';
import { fetchFlaggedMessages } from '../redux/flaggedMessagesSlice';
import Button from '../design-system/button/Button';

const LoadButton = () => {
  const [filters] = useFlaggedMessagesFilters({});
  const dispatch = useDispatch();

  const onClick = e => {
    e.preventDefault();
    dispatch(fetchFlaggedMessages(filters));
  }

  return (
    <Button onClick={onClick}>
      <span className="filter-label">Load data</span>
    </Button>
  );
}

export default LoadButton;
