import React from "react";
import Dropdown from "./design-system/dropdown/Dropdown";

function PromptSelection({ selectedPrompt, setSelectedPrompt }) {
  const handleChange = (event) => {
    setSelectedPrompt(event.target.value);
    // The API call will be made in the other component where the chat input is handled.
  };

  return (
    <div>
        <Dropdown value={selectedPrompt} onChange={handleChange} label="Prompt selection:" labelposition="top">
          <option value="Single Prompt">Single Prompt</option>
          <option value="Multi Prompt">Multi Prompt</option>
        </Dropdown>
    </div>
  );
}

export default PromptSelection;