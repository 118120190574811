// This is for firestore calls that go direct rather than via our server

import { db } from '../../Firebase/firebase';
import { collection, query, where, getDocs } from "firebase/firestore";

const getHistory = async (email) => {
  const q = query(collection(db, "questions"), where("email", "==", email));
  const snapshot = await getDocs(q);
  const questionsList = snapshot.docs.map((doc) => doc.data());
  let output = _groupDataByConversationNumber(questionsList);
  output = _orderConversationsByTimestamp(output);
  return output;
}

const _groupDataByConversationNumber = docs => {
  let output = [];
  let conversationNumbers = _extractConversationNumbers(docs);
  while(conversationNumbers.length > 0) {
    const conversationNumber = conversationNumbers.splice(0, 1);
    output.push(docs.filter(doc => doc.conversationNumber == conversationNumber));
  }
  return output;
}

const _extractConversationNumbers = docs => {
  const set = new Set();
  docs.forEach(doc => set.add(doc.conversationNumber));
  return [ ...set ];
}

const _orderConversationsByTimestamp = conversations => {
  for (let i = 0; i < conversations.length; i++) {
    conversations[i] = conversations[i].sort((a,b) => a.timestamp - b.timestamp);
  }
  return conversations;
}

export { getHistory };
