import React from 'react';
import Dropdown from '../design-system/dropdown/Dropdown';
import { useFlaggedMessagesFilters } from './FlaggedMessagesFiltersContext';

const FlaggedFilter = () => {
  const [filters, setFilters] = useFlaggedMessagesFilters({});

  return (
    <Dropdown
      label="Flagged"
      value={filters.flagged}
      onChange={e => setFilters({ ...filters, flagged: e.target.value })}
    >
      <option className="filter-option" value="">Don't care</option>
      <option className="filter-option" value="true">Yes</option>
      <option className="filter-option" value="false">No</option>
    </Dropdown>
  );
}

export default FlaggedFilter;
