import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as ApiStatus from './api-status';
import { getEmails } from '../firestore/firestore-edit-users';

const initialState = {
  data: null,
  status: ApiStatus.IDLE,
  error: null
}

export const fetchEmails = createAsyncThunk('emails/fetchEmails', async (uid) => await getEmails(uid));

const emailsSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    emailsDataFetched: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmails.pending, (state, action) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(fetchEmails.fulfilled, (state, action) => {
        state.status = ApiStatus.SUCCEEDED;
        state.data = action.payload;
        // console.log(JSON.stringify(state.data));
      })
      .addCase(fetchEmails.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.error = action.error.message;
      })
  }
});

export const { emailsDataFetched } = emailsSlice.actions

export default emailsSlice.reducer;

export const selectEmailsData = state => state.emails.data;
export const selectEmailsStatus = state => state.emails.status;
export const selectEmailsError = state => state.emails.error;
