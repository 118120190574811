import React, { useState } from 'react';
import Button from '../button/Button';
import Textarea from '../textarea/Textarea';
import '../layout/Modals.css';

const ExampleModal = ({ setShowModal }) => {
  const [description, setDescription] = useState("");

  const onChange = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  }

  return (
    <div className="modal-background">
      <div className="modal-container padding-all-10px">
        <div className="modal-header modal-header-contents">
          <span className="modal-header-title">Example modal</span>
          <span className="modal-header-close" onClick={() => setShowModal(false)}>X</span>
        </div>
        <div className="modal-content">
          <Textarea
            label="Label:"
            labelposition="top"
            value={description}
            onChange={onChange}
            rows={7}
          />
        </div>
        <div className="modal-footer modal-footer-contents">
          <Button onClick={() => setShowModal(false)} style={{ order: 1 }}>Submit</Button>
          <Button onClick={() => setShowModal(false)} style={{ order: 0 }}>Cancel</Button>
        </div>
      </div>
    </div>
  );
}

export default ExampleModal;
