import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditUsersData, updateUserRole } from '../redux/editUsersSlice';
import Button from '../design-system/button/Button';
import Dropdown from '../design-system/dropdown/Dropdown';
import { getUserSnapshot } from '../firestore/firestore-snapshots';

const EditRoleButton = () => {
  const dispatch = useDispatch();
  const userForEditing = useSelector(selectEditUsersData);
  const [newRole, setNewRole] = useState("tester");

  const onChange = e => {
    e.preventDefault();
    setNewRole(e.target.value);
  }

  const onClick = e => {
    e.preventDefault();
    if (userForEditing) {
      dispatch(updateUserRole({ uid: userForEditing.uid, newRole }));
    }
  }

  const [role, setRole] = useState(userForEditing.role);
  const onNextCallback = docSnapshot => {
    setRole(docSnapshot.data().role);
  }
  getUserSnapshot(userForEditing.uid, onNextCallback);

  if (!userForEditing) {
    return <div>No user loaded (EditRoleButton)</div>;
  }

  return (
    <div className="columns align-centre">
      <div><strong>Role:</strong></div>
      <div>{role}</div>
      <div><strong>Change role:</strong></div>
      <Dropdown value={newRole} onChange={onChange}>
        <option>free</option>
        <option>paid</option>
        <option>tester</option>
      </Dropdown>
      <Button onClick={onClick}>
        <span className="filter-label">Update role</span>
      </Button>
    </div>
  );
}

export default EditRoleButton;
