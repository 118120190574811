import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Button from '../../design-system/button/Button';
import SubscriptionStatus from '../../subscription-status/SubscriptionStatus';
import serverApi from '../../firestore/server-api';
import useUser from '../../redux-hooks/useUser';
import Message from '../../design-system/message/Message';

// FIXME Move to a stripe config file maybe?
// These are publishable keys so it's ok to have them in source control
const STRIPE_KEY = process.env.NODE_ENV === "production"
    ? "pk_live_51NVFO5J9eCrhoQj4ki0oxO1SINZqo7l9XUcwLSMDlEqLUYnYS8Ri1SIE7w6M6pK5tgbctLrwxFKueb9isckVRSv800zArMXZJR"
    : "pk_test_51NVFO5J9eCrhoQj470KGoTbIxltpBHJBb0OnPxpV8fZZHryXvH6hIn3HPM5j8LtfRacHLPEqs6zRKFfrfr01bKRp00byjZsKUv";
const stripe = await loadStripe(STRIPE_KEY);

const Pricing = () => {
  const user = useUser();

  const onClick = async () => {
    const res = await serverApi.createStripeCheckoutSession();
    stripe.redirectToCheckout({ sessionId: res.id });
  }

  if (user.data.role === "tester") {
    return (
      <div className="rows align-centre margin-all-10px">
        <Message type="info">You cannot subscribe with a tester account.</Message>
        <SubscriptionStatus />
      </div>
    )
  }

  // FIXME Add subscription dates ("Your subscription is active and will renew on <date>")
  if (user.data.role === "paid") {
    return (
      <div className="rows align-centre margin-all-10px">
        <Message type="success">Your subscription is active</Message>
        <SubscriptionStatus />
      </div>
    )
  }

  // FIXME Subscription cost and number of messages should come from the database
  const subscriptionCost = 32.99;
  const numMessages = 200;
  if (user.data.role === "free" || user.data.role === "admin") {
    return (
      <>
        {user.data.role === "admin"
        ? (
            <div className="rows align-centre margin-all-10px">
              <Message type="warning">
                <div>Your account is already an admin.</div>
                <div>You can subscribe to test Stripe integration.</div>
              </Message>
            </div>
          )
        : null}
        <div className="rows align-centre margin-all-10px">
          <div><strong>Joe Chip is awesome and you're losing money by not using it!</strong></div>
          <div>A subscription costs only ${subscriptionCost} per month and gives you {numMessages} messages.</div>
          <Button onClick={onClick}>Buy subscription</Button>
        </div>
      </>
    );
  }

  return null;
}

export default Pricing;
