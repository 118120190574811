import React from 'react';
import './Dropdown.css';

const Dropdown = (props) => {
  const className = "jcdropdown" + (props.className ? ` ${props.className}` : "");

  let { label, labelposition } = props;
  if (!labelposition) {
    labelposition = "left"
  }
  let labelComponent = null;
  if (label) {
    labelComponent = <div className="jcdropdown-label">{label}</div>;
  }

  return (
    <div className={`jcdropdown-container jcdropdown-container-${labelposition}-label`}>
      {labelComponent}
      <select {...props} className={className} />
    </div>
  );
}

export default Dropdown;
