import { SERVER_URL } from "./get-server-url";
import { auth } from '../../Firebase/firebase';

const _fetchWithIdToken = async (url, params) => {
  const controller = new AbortController();
  const idToken = await auth.currentUser.getIdToken(true);
  if (!params) {
    params = {};
  }
  params.headers = { ...params.headers, 'Authorization': `Bearer ${idToken}` };
  params.signal = controller.signal;
  setTimeout(() => controller.abort(), 30000);
  const response = await fetch(url, params);
  if (response.ok) {
    return response.status != 204 ? await response.json() : undefined;
  }
  const json = await response.json();
  return Promise.reject({ status: response.status, ...json });
}

const sendChatMessage = async ({ message, selectedModel, userId, chatHistory, selectedPersonality,selectedPrompt }) => {
  return await _fetchWithIdToken(`${SERVER_URL}/api/chat`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      message,
      selectedModel,
      userId,
      chatHistory,
      selectedPersonality,
      selectedPrompt
    }),
  });
}

const saveQuestion = async (email, inputText, responseText) => {
  await _fetchWithIdToken(`${SERVER_URL}/api/questions`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      email,
      inputText,
      responseText
    }),
  });
}

const flagMostRecentQuestion = async ({ email, description }) => {
  await _fetchWithIdToken(`${SERVER_URL}/api/questions/flag/${email}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ description }),
  });
}

const getFlaggedMessages = async (filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return await _fetchWithIdToken(`${SERVER_URL}/api/admin/questions?${queryParams}`);
}

const createStripeCheckoutSession = async () => {
  return await _fetchWithIdToken(`${SERVER_URL}/api/stripe/checkout-session`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });
}

const getDebugData = async () => {
  return await _fetchWithIdToken(`${SERVER_URL}/api/debug`);
}

const testFailure = async () => {
  const response = await _fetchWithIdToken(`${SERVER_URL}/api/fail`);
  console.log(`#### testFailure: response = ${JSON.stringify(response)}`);
  console.log(`# ${JSON.stringify(response.error)}`);
  console.log(`# ${JSON.stringify(response.message)}`);
  console.log(`# ${JSON.stringify(response.status)}`);
  console.log(`# ${JSON.stringify(response.ok)}`);
}

export default {
  sendChatMessage,
  saveQuestion,
  flagMostRecentQuestion,
  getFlaggedMessages,
  createStripeCheckoutSession,
  getDebugData,
  testFailure
};
