import { useSelector } from 'react-redux';
import { selectUserData, selectUserStatus, selectUserError } from '../redux/userSlice';

const useUser = () => {
  const data = useSelector(selectUserData);
  const status = useSelector(selectUserStatus);
  const error = useSelector(selectUserError);
  return { data, status, error };
}

export default useUser;
