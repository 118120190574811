import React from 'react';
import Dropdown from '../design-system/dropdown/Dropdown';
import useChatGptModel from './useChatGptModel';

const ChatGptModelSelection = () => {
  const [chatGptModel, setChatGptModel] = useChatGptModel();

  return (
    <div>
      <Dropdown
        value={chatGptModel}
        onChange={event => setChatGptModel(event.target.value)}
        label="Chat GPT model:"
        labelposition="top"
      >
        <option value="gpt-4">GPT-4</option>
        <option value="gpt-4-0613">GPT-4 w/fcalling</option>
        <option value="gpt-3.5-turbo">GPT 3.5</option>
      </Dropdown>
    </div>
  );
}

export default ChatGptModelSelection;
