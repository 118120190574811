import React from 'react';
import { useSelector } from "react-redux";
import { Navbar, Nav } from 'react-bootstrap';
import './NavBar.css';
import { NavLink } from 'react-router-dom';
import { selectUserData } from './redux/userSlice';

const NavBar = () => {
  const userData = useSelector(selectUserData);
  const showAdminLinks = userData?.role === "admin";

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="navbar">
      <NavLink to="/" className="navbar-brand">Joe Chip</NavLink>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto nav-links">
          {showAdminLinks ? (
            <>
              <NavLink className="nav-link red4 padding-left-10px padding-right-10px" to="/admin-settings">Admin Settings</NavLink>
              <NavLink className="nav-link red4 padding-left-10px padding-right-10px" to="/design-system">Design</NavLink>
              <NavLink className="nav-link red4 padding-left-10px padding-right-10px" to="/edit-users">Edit Users</NavLink>
              <NavLink className="nav-link red4 padding-left-10px padding-right-10px" to="/flagged-messages">Flagged</NavLink>
            </>
          ) : null}

          <NavLink className="nav-link" to="/chat">Chat</NavLink>
          <NavLink className="nav-link" to="/history">History</NavLink>
          {/* FIXME */}
          <NavLink className="nav-link" to="/pricing">Pricing</NavLink>
          <NavLink className="nav-link" to="/How">How it works</NavLink>
          <NavLink className="nav-link" to="/settings">Settings</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
