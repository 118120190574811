import React from 'react';
import { useSelector } from 'react-redux';
import SelectEmail from './SelectEmail';
import UserDetails from './UserDetails';
import EditRoleButton from './EditRoleButton';
import AddMessagesButton from './AddMessagesButton';
import { selectEditUsersData } from '../redux/editUsersSlice';
import '../design-system/navbar/NavBar.css';

const EditUsers = () => {
  const userForEditing = useSelector(selectEditUsersData);

  return (
    <>
      <div className="rows padding-all-10px second-nav-bar">
        <SelectEmail />
      </div>
      <div className="rows padding-all-10px">
        <h3>User details</h3>
        {userForEditing ? (
          <>
            <UserDetails />
            <EditRoleButton />
            <AddMessagesButton />
          </>
        ) : <div>No user loaded</div>}
      </div>
    </>
  );
}

export default EditUsers;
