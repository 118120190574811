// settings.js

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { auth, db } from '../../Firebase/firebase'; // Import auth and db
import { doc, updateDoc } from "firebase/firestore";
import { selectUserData } from '../redux/userSlice';

function Settings() {
  const userData = useSelector(selectUserData);
  const [handle, setHandle] = useState(''); // To store user's handle
  const [user, setUser] = useState(null); // To store authenticated user data
  const [successMessage, setSuccessMessage] = useState(''); // To store success message

  const [refresh, setRefresh] = useState(false); // State to force a re-render

  // useEffect hook to setup firebase auth state changed listener
  useEffect(() => {
    // Listener for auth state changes (signed in, signed out, etc.)
    auth.onAuthStateChanged((user) => {
      // Set user information on auth state changed
      setUser(user);
    });
  }, []);

  const updateHandle = async () => {
    try {
      // Update handle in Firestore
      await updateDoc(doc(db, "users", user.uid), {
        handle: handle,
      });

      // Update success message
      setSuccessMessage(`Your handle is now ${handle}`);

      // Set refresh to force a re-render
      setRefresh(!refresh);
    } catch (error) {
      console.log('Failed to update handle');
    }
  }

  return (
    <div>
      <h1>Account settings</h1>
      <label>
        New handle:
        <input type="text" value={handle} onChange={(e) => setHandle(e.target.value)} />
      </label>
      <button onClick={updateHandle}>Update handle</button>
      {successMessage && <p>{successMessage}</p>}
      {user && <p>Your email: {user.email}</p>}
      <p>Your handle: {userData.handle}</p>
      <p>Your status: {userData.status}</p>
      <p>Times you Interacted with the bot: {userData.timesInteractedWithBot}</p>
    </div>
  );
}

export default Settings;
