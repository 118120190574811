import React from "react";
import Dropdown from './design-system/dropdown/Dropdown';

function ModelSelection({ selectedModel, setSelectedModel }) {
  const handleChange = (event) => {
    setSelectedModel(event.target.value);
    // The API call will be made in the other component where the chat input is handled.
  };

  return (
    <div>
        <Dropdown value={selectedModel} onChange={handleChange} label="Model selection:" labelposition="top">
          <option value="gpt-4">GPT-4</option>
          <option value="gpt-4-0613">GPT-4 w/fcalling</option>
          <option value="gpt-3.5-turbo">GPT 3.5</option>
        </Dropdown>
    </div>
  );
}

export default ModelSelection;
