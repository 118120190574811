import React from 'react';
import './Text.css';

const Text = (props) => {
  let className = "jctext" + (props.className ? ` ${props.className}` : "");
  if (props.bold === "true") {
    className += " bold";
  }

  return (
    <span {...props} className={className} />
  );
}

export default Text;
