import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as ApiStatus from './api-status';
import { getUser } from '../firestore/firestore-collection-users';

const initialState = {
  data: null,
  status: ApiStatus.IDLE,
  error: null
}

export const fetchUser = createAsyncThunk('user/fetchFirestoreUser', async (uid) => await getUser(uid));

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userDataFetched: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = ApiStatus.SUCCEEDED;
        state.data = {
          ...state.data,
          ...action.payload
        };
        // console.log(JSON.stringify(state.data));
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.error = action.error.message;
      })
  }
});

export const { userDataFetched } = userSlice.actions

export default userSlice.reducer;

export const selectUserData = state => state.user.data;
export const selectUserStatus = state => state.user.status;
export const selectUserError = state => state.user.error;
