// This is for firestore snapshots that trigger whenever the database is updated

import { db } from '../../Firebase/firebase';
import { doc, onSnapshot } from "firebase/firestore";

const getUserSnapshot = (uid, onNextCallback) => {
  const userDoc = doc(db, 'users', uid);
  return onSnapshot(userDoc, onNextCallback);
}

export { getUserSnapshot };
