// This is for firestore calls that go direct rather than via our server

import { db } from '../../Firebase/firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { addMonths } from '../utils/dates';

const getUser = async (uid) => {
  const userDoc = doc(db, 'users', uid);
  const userSnapshot = await getDoc(userDoc);
  return userSnapshot.data();
}

const decrementMessageCount = async (uid) => {
  const userDoc = doc(db, 'users', uid);
  const userSnapshot = await getDoc(userDoc);
  const userData = userSnapshot.data();
  await updateDoc(userDoc, { messagesRemaining: userData.messagesRemaining - 1 });
}

const refreshMessages = async (uid, lastMessageRefreshDate) => {
  const messageLimitsDoc = doc(db, 'misc', 'message-limits');
  const messageLimitsSnapshot = await getDoc(messageLimitsDoc);
  const messageLimitsData = messageLimitsSnapshot.data();

  const userDoc = doc(db, 'users', uid);
  const userSnapshot = await getDoc(userDoc);
  const userData = userSnapshot.data();

  if (!lastMessageRefreshDate || lastMessageRefreshDate < addMonths(new Date(), -1)) {
    if (userData.messagesRemaining < messageLimitsData[userData.role]) {
      await updateDoc(userDoc, {
        messagesRemaining: messageLimitsData[userData.role],
        lastMessageRefreshDate: new Date().getTime()
      });
    }
  }
}

const incrementConversationNumber = async (uid) => {
  const userDoc = doc(db, 'users', uid);
  const userSnapshot = await getDoc(userDoc);
  const userData = userSnapshot.data();
  const { conversationNumber } = userData;
  await updateDoc(userDoc, { conversationNumber: conversationNumber + 1 });
}

export { getUser, decrementMessageCount, refreshMessages, incrementConversationNumber };
