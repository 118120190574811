import React from "react";
import Dropdown from "./design-system/dropdown/Dropdown";

function PersonalitySelection({ selectedPersonality, setSelectedPersonality }) {
  const handleChange = (event) => {
    setSelectedPersonality(event.target.value);
    // The API call will be made in the other component where the chat input is handled.
  };

  return (
    <div>
        <Dropdown value={selectedPersonality} onChange={handleChange} label="Personality selection:" labelposition="top">
          <option value="JoePokerCoach">Joe Poker Coach</option>
          <option value="JoeTest">Joe Test</option>
        </Dropdown>
    </div>
  );
}

export default PersonalitySelection;
