import React from 'react';
import { useSelector } from 'react-redux';
import { selectEditUsersData } from '../redux/editUsersSlice';

const UserDetails = () => {
  const userForEditing = useSelector(selectEditUsersData);

  if (!userForEditing) {
    return <div>No user loaded (UserDetails)</div>
  }

  return (
    <div className="columns">
      <div><strong>Email:</strong></div>
      <div>{userForEditing.email}</div>
    </div>
  );
}

export default UserDetails;
