import React from 'react';
import './Textarea.css';

const Textarea = (props) => {
  const className = "jctextarea" + (props.className ? ` ${props.className}` : "");

  let { label, labelposition } = props;
  if (!labelposition) {
    labelposition = "left"
  }
  let labelComponent = null;
  if (label) {
    labelComponent = <div className="jctextarea-label">{label}</div>;
  }

  return (
    <div className={`jctextarea-container jctextarea-container-${labelposition}-label`}>
      {labelComponent}
      <textarea {...props} type='text' className={className} />
    </div>
  );
}

export default Textarea;
