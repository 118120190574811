import React from 'react';
import './Textfield.css';

const Textfield = (props) => {
  let { label, labelposition } = props;
  if (!labelposition) {
    labelposition = "left"
  }
  let labelComponent = null;
  if (label) {
    labelComponent = <div className="jctextfield-label">{label}</div>;
  }

  const containerClassName = `jctextfield-container jctextfield-container-${labelposition}-label` + (props.className ? ` ${props.className}` : "");

  return (
    <div className={containerClassName}>
      {labelComponent}
      <input type='text' {...props} className="jctextfield full-width" />
    </div>
  );
}

export default Textfield;
