// FIXME This should probably be a context object or global state.
// otherwise I'm worried we will create multiple unnecessary connections.
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXtOSRiq7QbYBYKtewmnsMWVegJ-xEVOU",
  authDomain: "darming-1e239.firebaseapp.com",
  projectId: "darming-1e239",
  storageBucket: "darming-1e239.appspot.com",
  messagingSenderId: "36333866913",
  appId: "1:36333866913:web:e4da1dfb53ba35517545e0",
  measurementId: "G-PYLM5ZLRDF"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
