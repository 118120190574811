import React from 'react';
import "./WelcomeSection.css";
import Text from '../design-system/text/Text';

const WelcomeSection = () => {
  const renderTitleText = () => (
    <Text bold="true" className="title-text">Meet Joe Chip: Your New Poker Buddy</Text>
  );

  const renderImage = () => (
    <img src="/JoeChip.png" alt="Joe Chip Logo" className="image" />
  );

  const renderDescription = () => (
    <Text className="description">
      Hey there! Meet Joe Chip, your dedicated poker AI guru.
      <br/>
      <br/>
      Drawing on the combined wisdom, expertise, and 60 years of experience from three seasoned poker players, we've harnessed cutting-edge AI to address all your poker queries.
      We're gearing up for our official launch in October.
      <br/>
      <br/>
      Interested? Drop your email address, and we'll ping you once Joe is all set to chat!
    </Text>
  );

  return (
    <div className="width-100 flex cc">
      <div className="width-60">
        <div className="big-col welcome-section">
          <div className="rows align-centre">
            <div className="small-row padding-bottom-20px">
              {renderTitleText()}
            </div>
            <div className="big-row cols">
              <div className="small-col">
                {renderImage()}
              </div>
              <div className="big-col padding-all-20px">
                {renderDescription()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeSection;
