import React from 'react';
import './List.css';

const List = (props) => {
  const className = "list no-bullets" + (props.className ? ` ${props.className}` : "");

  return (
    <ul {...props} className={className} />
  );
}

export default List;
