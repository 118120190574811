import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../design-system/button/Button';
import Textarea from '../design-system/textarea/Textarea';
import { selectUserData } from '../redux/userSlice';
import serverApi from '../firestore/server-api';
import '../design-system/layout/Modals.css';

const FlagAnswerDialog = ({ setShowDialog }) => {
  const userData = useSelector(selectUserData);
  const [description, setDescription] = useState("");

  const onChange = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  }

  const onClick = async (e) => {
    e.preventDefault();
    setShowDialog(false);
    await serverApi.flagMostRecentQuestion({ email: userData.email, description });
  }

  return (
    <div className="modal-background">
      <div className="modal-container padding-all-10px">
        <div className="modal-header modal-header-contents">
          <span className="modal-header-title">Flag Current Answer</span>
          <span className="modal-header-close" onClick={() => setShowDialog(false)}>X</span>
        </div>
        <div className="modal-content">
          <Textarea
            label="What is wrong with the current answer:"
            labelposition="top"
            value={description}
            onChange={onChange}
            rows={7}
          />
        </div>
        <div className="modal-footer modal-footer-contents">
          <Button onClick={onClick} style={{ order: 1 }}>Submit</Button>
          <Button onClick={() => setShowDialog(false)} style={{ order: 0 }}>Cancel</Button>
        </div>
      </div>
    </div>
  );
}

export default FlagAnswerDialog;
