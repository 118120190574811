import React from 'react';
import './Message.css';

const VALID_MESSAGE_TYPES = [ "info", "success", "warning", "error" ];

const Message = props => {
  if (props.type) {
    if(!VALID_MESSAGE_TYPES.includes(props.type)) {
      throw new Error(`Invalid message type '${props.type}'. Valid values are "${VALID_MESSAGE_TYPES.join("\", \"")}" or no value.`);
    }
  }

  const className = `message ${props.type || "default"} centre-text`;
  return (
    <div {...props} className={className} />
  );
}

export default Message;
