import React from 'react';
import Dropdown from '../design-system/dropdown/Dropdown';
import usePersonality from './usePersonality';

const PersonalitySelection = () => {
  const [personality, setPersonality] = usePersonality();

  return (
    <div>
      <Dropdown
        value={personality}
        onChange={event => setPersonality(event.target.value)}
        label="Personality:"
        labelposition="top"
      >
        <option value="JoePokerCoach">Joe Poker Coach</option>
        <option value="JoeTest">Joe Test</option>
      </Dropdown>
    </div>
  );
}

export default PersonalitySelection;
