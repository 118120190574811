import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditUsersData, updateUserMessages } from '../redux/editUsersSlice';
import Button from '../design-system/button/Button';
import Textfield from '../design-system/textfield/Textfield';
import { getUserSnapshot } from '../firestore/firestore-snapshots';

const AddMessagesButton = () => {
  const dispatch = useDispatch();
  const userForEditing = useSelector(selectEditUsersData);
  const [additionalMessages, setAdditionalMessages] = useState(100);

  const onChange = e => {
    e.preventDefault();
    setAdditionalMessages(Number(e.target.value));
  }

  const onClick = e => {
    e.preventDefault();
    if (userForEditing) {
      dispatch(updateUserMessages({ uid: userForEditing.uid, additionalMessages }));
    }
  }

  const [messagesRemaining, setMessagesRemaining] = useState(userForEditing.messagesRemaining);
  const onNextCallback = docSnapshot => {
    setMessagesRemaining(docSnapshot.data().messagesRemaining);
  }
  getUserSnapshot(userForEditing.uid, onNextCallback);

  if (!userForEditing) {
    return <div>No user loaded (AddMessagesButton)</div>;
  }

  return (
    <div className="columns align-centre">
      <div><strong>Messages remaining:</strong></div>
      <div>{messagesRemaining}</div>
      <div><strong>Add messages:</strong></div>
      <Textfield type="number" value={additionalMessages} onChange={onChange} />
      <Button onClick={onClick}>
        <span className="filter-label">Add messages</span>
      </Button>
    </div>
  );
}

export default AddMessagesButton;
