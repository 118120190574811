import React from 'react';
import EmailFilter from './EmailFilter';
import TimestampFilter from './TimestampFilter';
import FlaggedFilter from './FlaggedFilter';
import LoadButton from './LoadButton';
import '../design-system/navbar/NavBar.css';

const FlaggedMessagesFilters = () => {
  return (
    <div className="columns padding-all-20px second-nav-bar">
      <EmailFilter />
      <TimestampFilter label="Timestamp start" targetProperty="timestampStart" />
      <TimestampFilter label="Timestamp end" targetProperty="timestampEnd" />
      <FlaggedFilter />
      <LoadButton />
    </div>
  );
}

export default FlaggedMessagesFilters;
