// This is for firestore calls that go direct rather than via our server

import { db } from '../../Firebase/firebase';
import { collection, doc, query, where, getDoc, getDocs, updateDoc } from "firebase/firestore";

const getEmails = async () => {
  const q = query(collection(db, "users"));
  const snapshot = await getDocs(q);
  const emails = snapshot.docs.map(doc => doc.data().email);
  return [ ...new Set(emails) ].sort();
}

const getUserForEditing = async email => {
  const q = query(collection(db, "users"), where("email", "==", email));
  const snapshot = await getDocs(q);
  if (snapshot.docs.length !== 1) {
    throw `ERROR: Expected exactly one user doc for email ${email} but found ${snapshot.docs.length} docs`;
  }
  const userDoc = snapshot.docs[0].data();
  const user = {
    email: userDoc.email,
    uid: snapshot.docs[0].id,
    messagesRemaining: userDoc.messagesRemaining,
    role: userDoc.role
  };
  return user;
}

const updateEditedUserRole = async ({ uid, newRole }) => {
  const userDoc = doc(db, 'users', uid);
  await updateDoc(userDoc, { role: newRole });
};

const updateEditedUserMessages = async ({ uid, additionalMessages }) => {
  const userDoc = doc(db, 'users', uid);
  const userSnapshot = await getDoc(userDoc);
  const userData = userSnapshot.data();
  const currentMessagesRemaining = userData.messagesRemaining;
  await updateDoc(userDoc, { messagesRemaining: currentMessagesRemaining + additionalMessages });
};

export { getEmails, getUserForEditing, updateEditedUserRole, updateEditedUserMessages };
