import React from 'react';
import Message from '../../design-system/message/Message';
import SubscriptionStatus from '../../subscription-status/SubscriptionStatus';

const PaymentSuccess = () => {
  return (
    <div className="rows align-centre margin-all-10px">
      <Message type="success">Payment was successful!</Message>
      <SubscriptionStatus />
    </div>
  );
}

export default PaymentSuccess;
