// ChatWindow.js

import React, { useState, useEffect, createRef } from 'react'; // Import createRef
import { useSelector } from 'react-redux';
import setupDatabase from './utils/dbSetup';
import './ChatWindow.css';
import List from './design-system/list/List';
import Button from './design-system/button/Button';
import Textfield from './design-system/textfield/Textfield';
import serverApi from './firestore/server-api';
import * as firestoreCollectionUsers from './firestore/firestore-collection-users';
import { selectUserData } from './redux/userSlice';

function ChatWindow({ selectedModel, selectedPersonality, selectedPrompt }) {
  const userData = useSelector(selectUserData);

  const [messages, setMessages] = useState([
    {sender: 'Joe', text: 'Hi there, I am Joe, your friendly poker coach.'},
    {sender: 'Joe', text: 'Ask me any poker related question and I will try to help.'},
    {sender: 'Joe', text: 'The more detail the better.'}
  ]);

  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const messagesEndRef = createRef(); // Create a ref

  useEffect(() => {
    const loadConversation = async () => {
      const db = await setupDatabase();
      let conversation = await db.get('conversations', userData.uid);
      if (conversation) {
        setMessages(conversation.messages);
      }
    };
    loadConversation();
  }, [userData]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSend = async (event) => {
    try {
      event.preventDefault();
      if (!input) return;
      const userMessage = { sender: userData.handle, text: input };
      const db = await setupDatabase();
      let conversation = await db.get('conversations', userData.uid);
      if (!conversation) {
        conversation = { userId: userData.uid, messages: [] };
      }
      conversation.messages.push(userMessage);
      setMessages(messages => [...messages, userMessage]);
      await db.put('conversations', conversation);
      setIsLoading(true);
      setInput('');

      const response = await serverApi.sendChatMessage({

        selectedPrompt: selectedPrompt,
        message: input,
        selectedModel: selectedModel,
        userId: userData.uid,
        chatHistory: conversation.messages,
        selectedPersonality: selectedPersonality,
        
      });
      

      const assistantMessage = { sender: 'Joe', text: response.message };
      setMessages(messages => [...messages, assistantMessage]);
      conversation.messages.push(assistantMessage);
      await db.put('conversations', conversation);
      await serverApi.saveQuestion(userData.email, userMessage.text, response.message);
      await firestoreCollectionUsers.decrementMessageCount(userData.uid);
    } catch (error) {
      console.log(`error = ${JSON.stringify(error)}`);
      console.log(`error status = ${error.status}`);
      console.log(`error message = ${error.message}`);
      setError(error);
    }

    setIsLoading(false);
  };

  const clearHistory = async () => {
    await firestoreCollectionUsers.incrementConversationNumber(userData.uid);
    const db = await setupDatabase();
    await db.delete('conversations', userData.uid);
    setMessages([]);
  };

  return (
    <List className="margin-all-10px full-width">
      <Button className="full-width" onClick={clearHistory}>Clear history</Button>
      <div className="full-width">
        <div className="fake-field height-50vh">
          <div className="chat-window-messages full-width padding-right-10px height-100pc">
            {messages.map((message, index) => (
              <p key={index} style={{textAlign: message.sender === 'Joe' ? 'left' : 'right'}} className="padding-bottom-10px">
                <strong>{message.sender}</strong>: {message.text}
              </p>
            ))}
            <div ref={messagesEndRef} /> {/* Add a div at the end of your messages */}
          </div>
        </div>
      </div>
      <form className="chat-window-form full-width columns" onSubmit={handleSend}>
        <Textfield value={input} onChange={(e) => setInput(e.target.value)} className="big-col" />
        <Button type='submit' disabled={isLoading} className="small-col small-col-12em">{isLoading ? 'Joe is thinking...' : 'Send'}</Button>
      </form>
      {error ? <div className="error-message">{error.message}</div> : null}
    </List>
  );
}

export default ChatWindow;
