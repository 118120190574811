import React from 'react';
import Dropdown from '../design-system/dropdown/Dropdown';
import usePrompt from './usePrompt';

const PromptSelection = () => {
  const [prompt, setPrompt] = usePrompt();

  return (
    <div>
      <Dropdown
        value={prompt}
        onChange={event => setPrompt(event.target.value)}
        label="Prompt:"
        labelposition="top"
      >
        <option value="Single Prompt">Single Prompt</option>
        <option value="Multi Prompt">Multi Prompt</option>
      </Dropdown>
    </div>
  );
}

export default PromptSelection;
