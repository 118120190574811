import React from 'react';
import useUser from '../redux-hooks/useUser';

const SubscriptionStatus = () => {
  const user = useUser();

  return (
    <div>
      <div><strong>Email:</strong> {user.data.email}</div>
      <div><strong>Account status:</strong> {user.data.role}</div>
    </div>
  );
}

export default SubscriptionStatus;
