import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as ApiStatus from './api-status';
import { getUserForEditing, updateEditedUserRole, updateEditedUserMessages } from '../firestore/firestore-edit-users';

const initialState = {
  data: null,
  status: ApiStatus.IDLE,
  error: null
}

export const fetchUserForEditing = createAsyncThunk('edit-users/fetchUserForEditing', async (email) => await getUserForEditing(email));
export const updateUserRole = createAsyncThunk('edit-users/updateUserRole', async (data) => await updateEditedUserRole(data));
export const updateUserMessages = createAsyncThunk('edit-users/updateUserMessages', async (data) => await updateEditedUserMessages(data));

const editUsersSlice = createSlice({
  name: 'edit-users',
  initialState,
  reducers: {
    editUsersDataFetched: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserForEditing.pending, (state, action) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(fetchUserForEditing.fulfilled, (state, action) => {
        state.status = ApiStatus.SUCCEEDED;
        state.data = action.payload;
        // console.log(JSON.stringify(state.data));
      })
      .addCase(fetchUserForEditing.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.error = action.error.message;
      })
  }
});

export const { editUsersDataFetched } = editUsersSlice.actions

export default editUsersSlice.reducer;

export const selectEditUsersData = state => state.editUsers.data;
export const selectEditUsersStatus = state => state.editUsers.status;
export const selectEditUsersError = state => state.editUsers.error;
