import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as ApiStatus from './api-status';
import serverApi from '../firestore/server-api';

const initialState = {
  data: {},
  status: ApiStatus.IDLE,
  error: null
}

export const fetchFlaggedMessages = createAsyncThunk('flagged-messages/fetchHistory', async (filters) => await serverApi.getFlaggedMessages(filters));

const flaggedMessagesSlice = createSlice({
  name: 'flagged-messages',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFlaggedMessages.pending, (state, action) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(fetchFlaggedMessages.fulfilled, (state, action) => {
        state.status = ApiStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchFlaggedMessages.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.error = action.error.message;
      })
  }
});

// FIXME No actions as admin history is read-only
// export const { postAdded, postUpdated, reactionAdded } = postsSlice.actions

export default flaggedMessagesSlice.reducer;

export const selectFlaggedMessagesData = state => state.flaggedMessages.data;
export const selectFlaggedMessagesStatus = state => state.flaggedMessages.status;
export const selectFlaggedMessagesError = state => state.flaggedMessages.error;
